<template>
  <g>
    <g id="g25" transform="rotate(90,2187.5,6855)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path27"/></g
    ><g id="g29" transform="rotate(90,-963.5,2976)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path31"/></g
    ><g id="g33" transform="rotate(90,-3945.5,5769)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path35"/></g
    ><g id="g37" transform="rotate(90,-1422,3245.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path39"/></g
    ><g id="g41" transform="rotate(90,2457,6396.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path43"/></g
    ><g id="g45" transform="rotate(90,-336,9378.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path47"/></g
    ><g id="g49" transform="rotate(90,513,4315.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path51"/></g
    ><g id="g53" transform="rotate(90,1117.5,4920)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path55"/></g
    ><path
      d="m 5911,3486.5 c 0,-10.2173 8.2827,-18.5 18.5,-18.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path57"
    /><path
      d="M 5929,3467 H 9213"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path59"
    /><g id="g61" transform="rotate(-90,4786,-1143.5)"
      ><path
        d="M -16.02176,-9.24949 C -12.71714,-14.9737 -6.60962,-18.5 0,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path63"/></g
    ><g
      id="g65"
      transform="matrix(-0.49997,-0.86604,0.86604,-0.49997,5910.5,3675.5)"
      ><path
        d="M 9.24949,16.02176 C 2.51403,19.9102 -5.93422,19.17085 -11.89187,14.17157 -17.84952,9.17229 -20.04432,0.98068 -17.38425,-6.32756 -14.72418,-13.6358 -7.7773,-18.5 0,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path67"/></g
    ><g
      id="g69"
      transform="matrix(0.49997,0.86604,-0.86604,0.49997,5877.5,3694.5)"
      ><path
        d="M -16.02176,-9.24949 C -12.71714,-14.9737 -6.60962,-18.5 0,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path71"/></g
    ><g id="g73" transform="rotate(90,725,4211.5)"
      ><path
        d="m -18.5,0 c 0,-10.21727 8.28273,-18.5 18.5,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path75"/></g
    ><path
      d="m 4955,3486 v 156"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path77"
    /><g
      id="g79"
      transform="matrix(-0.86604,0.49997,-0.49997,-0.86604,4936.5,3642.5)"
      ><path
        d="M -16.02176,-9.24949 C -12.71714,-14.9737 -6.60962,-18.5 0,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path81"/></g
    ><g
      id="g83"
      transform="matrix(0.86604,-0.49997,0.49997,0.86604,4955.5,3675.5)"
      ><path
        d="M 9.24949,16.02176 C 2.51403,19.9102 -5.93422,19.17085 -11.89187,14.17157 -17.84952,9.17229 -20.04432,0.98068 -17.38425,-6.32756 -14.72418,-13.6358 -7.7773,-18.5 0,-18.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path85"/></g
    ><path
      d="m 4972.478,3685.2505 c 3.3047,-5.7241 9.4121,-9.2505 16.022,-9.2505"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path87"
    /><g id="g89" transform="rotate(90,639.5,4555)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path91"/></g
    ><g id="g93" transform="rotate(90,878,4793.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path95"/></g
    ><path
      d="M 5910,3642 V 3486"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path97"
    /><path
      d="m 9213.5,3467 c 62.6846,0 113.5,50.8157 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path99"
    /><path
      d="m 9326,3580 v 6870"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path101"
    /><g id="g103" transform="rotate(90,-618.5,9832)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path105"/></g
    ><path
      d="M 9213,10563 H 1652"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path107"
    /><g id="g109" transform="rotate(180,826.25,5225.25)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path111"/></g
    ><path
      d="M 1539,10450 V 3580"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path113"
    /><g id="g115" transform="rotate(-90,2616.5,964)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path117"/></g
    ><path
      d="M 1652,3467 H 4936"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path119"
    /><path
      d="m 4988,3675 h 889"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path121"
    /><g id="g123" transform="rotate(90,-2960,7433.5)"
      ><path
        d="M 0,-94.5 C 52.19091,-94.5 94.5,-52.19091 94.5,0 94.5,52.19091 52.19091,94.5 0,94.5 -52.19091,94.5 -94.5,52.19091 -94.5,0 -94.5,-52.19091 -52.19091,-94.5 0,-94.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path125"/></g
    ><g id="g127" transform="rotate(90,-3919,6474.5)"
      ><path
        d="M 0,-94.5 C 52.19091,-94.5 94.5,-52.19091 94.5,0 94.5,52.19091 52.19091,94.5 0,94.5 -52.19091,94.5 -94.5,52.19091 -94.5,0 -94.5,-52.19091 -52.19091,-94.5 0,-94.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path129"/></g
    ><g id="g131" transform="rotate(90,-2000.5,8393)"
      ><path
        d="M 0,-94.5 C 52.19091,-94.5 94.5,-52.19091 94.5,0 94.5,52.19091 52.19091,94.5 0,94.5 -52.19091,94.5 -94.5,52.19091 -94.5,0 -94.5,-52.19091 -52.19091,-94.5 0,-94.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path133"/></g
    ><g id="g135" transform="rotate(90,-1041.5,9352)"
      ><path
        d="M 0,-94.5 C 52.19091,-94.5 94.5,-52.19091 94.5,0 94.5,52.19091 52.19091,94.5 0,94.5 -52.19091,94.5 -94.5,52.19091 -94.5,0 -94.5,-52.19091 -52.19091,-94.5 0,-94.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path137"
    /></g>
  </g>
</template>
<script>
export default {
  name: "PressureDev260Top",
};
</script>
